<template>
    <BT-Blade-Items
        canDelete
        :canSelect="false"
        navigation="courier-requests"
        :onCanDelete="item => item.isApproved == null || item.isApproved == false"
        useServerPagination
        :headers="[
            { text: 'Company Name', value: 'seller.companyName' },
            { text: 'Date', value: 'requestedOn', textFilter: 'toLongDateAndTime' },
            { text: 'Message', value: 'requesterMessage' },
            { text: 'Status', value: 'isApproved', display: true }]"
        title="Courier Requests">
        <template v-slot:isApproved="{ item }">
            <span v-if="item.isApproved == null">Awaiting Response</span>
            <span v-else-if="item.isApproved === true">Approved</span>
            <span v-else-if="item.isApproved === false">Rejected</span>
        </template>
    </BT-Blade-Items> 
</template>

<script>
export default {
    name: 'Courier-Requests-Blade'
}
</script>